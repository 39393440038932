import React from "react";

function HeaderPhoto({ photo, title, node }) {
  return (
    <div className="background" style={{ backgroundImage: `url(${photo})` }}>
      <h1>{title}</h1>
      {node}
      <p></p>
      <div style={{ color: "white", zIndex: 2 }}></div>
      <div className="tint"></div>
    </div>
  );
}

export default HeaderPhoto;
