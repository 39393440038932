import React from "react";
import HeaderPhoto from "../components/HeaderPhoto";
import Layout from "../components/Layout";
import photo from "../images/kontakt.webp";
import * as styles from "../styles/kontakt.module.css";

export default function Kontakt() {
  return (
    <Layout>
      <HeaderPhoto title="Kontakt" photo={photo} />
      <div className={styles.kontakt}>
        <div className={styles.kontaktInfo}>
          <div className={styles.main}>Adresa</div>
          <div>Vojvode Stepe 106, Čačak</div>
          <div className={`${styles.main} ${styles.mtk}`}>Telefon</div>
          <div>+381643896644</div>
          <div className={`${styles.main} ${styles.mtk}`}>E-mail</div>
          <div>sonjanikacevic83@gmail.com</div>
        </div>
        <div className={styles.map}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2875.2730541907918!2d20.33853961576972!3d43.89161114499884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475773af1a153003%3A0xb7ec6efef682bd56!2sNutricionisti%C4%8Dki%20Studio%20Sonja%20Nika%C4%8Devi%C4%87!5e0!3m2!1sen!2srs!4v1664734791897!5m2!1sen!2srs"
            width="100%"
            height="400"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Nutricionista Sonja Nikacevic"
          ></iframe>
        </div>
      </div>
    </Layout>
  );
}

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
